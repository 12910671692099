<template>
  <Flatfinder
    class="residential-flatfinder"
    :value="flatfinders"
    :suggested="suggestedFlatfinder"
    :properties="properties"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import Flatfinder from '@kvass/flatfinder'

export default {
  computed: {
    ...mapGetters('Residential', ['flatfinders', 'suggestedFlatfinder']),
    properties() {
      return KvassConfig.get('siteSettings.featuredResidentialProperties') || []
    },
  },
  components: {
    Flatfinder,
  },
}
</script>
