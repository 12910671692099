import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from './i18n'
import { path } from 'ramda'
import API from './api'
import '@/components'
import '@/styles/main.scss'
import BrowserApiMixin from '@/mixins/browser-api'
import TemplateSubPage from '@kvass/template-subpage'
import { PageInstaller as ResidentialTemplatePageInstaller } from '@kvass/template-residential-template'
import './gdpr'
import './browser-api'


Vue.prototype.$api = API
Vue.prototype.$path = function() {
  let [key, obj, ...args] = arguments

  key = typeof key === 'string' ? key.split('.') : key
  obj = obj || this

  return path.apply(this, [key, obj, ...args])
}
Vue.use(TemplateSubPage, { router })
Vue.use(ResidentialTemplatePageInstaller, { router })

document.addEventListener('click', function(e) {
  if (e.target.tagName !== 'A') return
  if (e.target.hostname !== window.location.hostname) return

  let getQS = v => v.split('?')[1]
  let getNonQS = v => v.split('?')[0]
  let qs = new URLSearchParams(window.location.search)
  let aQs = new URLSearchParams(getQS(e.target.href))

  if (qs.has('previewToken')) aQs.append('previewToken', qs.get('previewToken'))

  let base = getNonQS(e.target.href).replace(window.location.origin, '')
  let finalQs = aQs.toString() ? `?${aQs.toString()}` : ''
  return (e.target.href = base + finalQs)
})

Vue.config.productionTip = false

new Vue({
  mixins: [BrowserApiMixin()],
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
