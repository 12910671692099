<template>
  <Attachments class="project-attachments" :value="$path('media.attachments', item)" />
</template>

<script>
import { mapState } from 'vuex'
import Attachments from '@/components/Attachments'

export default {
  computed: {
    ...mapState('Project', ['item']),
  },
  components: {
    Attachments,
  },
}
</script>

<style></style>
