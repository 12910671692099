<template>
  <Attachments class="residential-attachments" :value="mergedAttachments" />
</template>

<script>
import { mapState } from 'vuex'
import Attachments from '@/components/Attachments'

export default {
  computed: {
    ...mapState('Residential', ['item']),
    mergedAttachments() {
      return [
        ...(this.$path('item.media.attachments') || []),
        ...(this.$path('item.project.media.attachments') || []),
      ]
    },
  },
  components: {
    Attachments,
  },
}
</script>

<style></style>
