<template>
  <Loader :value="promises.fetch" theme="default">
    <div class="project-residential-page">
      <div class="project-residential-page__header section__header">
        <ResidentialCover />
        <ResidentialSummary />
      </div>
      <section class="section section__info">
        <ScrollAnchor value="map" offset="-8rem" :delay="750" />
        <div class="container">
          <ResidentialInfo />
          <ResidentialMap />
        </div>
      </section>
      <section class="section section--primary section__lead">
        <div class="container">
          <div class="row wrap g-6">
            <div class="grow-1">
              <ResidentialOpenhouse v-if="showOpenhouse" class="mb-6" />
              <ResidentialRoles />
            </div>
            <ScrollAnchor value="lead" offset="-8rem" :delay="750" />
            <ResidentialLead class="grow-1" />
          </div>
        </div>
      </section>
      <section
        v-if="$path('media.floorplan.length', residential)"
        class="section section__floorplan"
      >
        <div class="container">
          <ScrollAnchor value="floorplan" offset="-8rem" :delay="750" />
          <ResidentialFloorplan />
        </div>
      </section>
      <section
        v-if="$path('shop.published', residential) && !hideShop"
        class="section section__shop section--no-p"
      >
        <div class="container">
          <ScrollAnchor value="shop" offset="-8rem" :delay="750" />
          <ResidentialShopIntro />
        </div>
      </section>
      <section class="section section--primary section__properties">
        <div class="container">
          <ScrollAnchor value="properties" offset="-8rem" :delay="750" />
          <ResidentialProperties />
        </div>
      </section>
      <section>
        <div class="container section__additional-info">
          <ScrollAnchor value="additional-info" offset="-8rem" :delay="750" />
          <ProjectAdditionalInfo />
        </div>
      </section>
      <section class="section section__residential-templates">
        <div class="container">
          <ScrollAnchor value="residential-templates" offset="-8rem" :delay="750" />
          <ProjectResidentialTemplates />
        </div>
      </section>
      <section v-if="$path('media.gallery.length', residential)" class="section section__images">
        <div class="container">
          <ScrollAnchor value="gallery" offset="-8rem" :delay="750" />
          <ResidentialGallery />
        </div>
      </section>
      <section
        class="section section__attachments"
        v-if="
          $path('media.attachments.length', residential) ||
            $path('project.media.attachments.length', residential)
        "
      >
        <div class="container">
          <ScrollAnchor value="attachments" offset="-8rem" :delay="750" />
          <ResidentialAttachments />
        </div>
      </section>
      <a
        v-if="$path('sale.bidUrl', residential) && residential.status === 'sale'"
        :href="'//' + $path('sale.bidUrl', residential)"
        target="_blank"
        class="cta"
      >
        <div class="mr-2">{{ $t('bidAction') }}</div>
        <FontAwesomeIcon :icon="['far', 'arrow-down']" />
      </a>
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ResidentialComponents from '@/components/Residential'
import ProjectComponents from '@/components/Project'
import Residentials from '@/components/Residentials'
import BrowserApiMixin from '@/mixins/browser-api'
import ScrollAnchor from '@kvass/scroll-anchor'

const PrefixKeys = (prefix, obj) =>
  Object.keys(obj).reduce((acc, key) => ({ ...acc, ...{ [prefix + key]: obj[key] } }), {})

export default {
  mixins: [BrowserApiMixin('project-residential')],
  props: {
    id: String,
  },
  watch: {
    id: {
      handler(val) {
        return this.fetch(val).then(data => {
          if (!data) return this.$router.push({ name: 'notfound' })
        })
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('Project', {
      item: 'item',
      promises: 'promises',
      residential: state => (state.item.residentials || [])[0] || {},
    }),
    hideShop() {
      return KvassConfig.get('siteSettings.hideShop')
    },
    showOpenhouse() {
      if (!this.item.id) return false
      if (this.$path('item.project.openhouses.length')) return true
      return !KvassConfig.get('siteSettings.hideNoOpenhouse')
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
  },
  metaInfo() {
    return {
      title: this.item.name || `${this.$t('loading', { resource: null })}...`,
    }
  },
  components: {
    ...PrefixKeys('Residential', ResidentialComponents),
    ...PrefixKeys('Project', ProjectComponents),
    Residentials,
    ScrollAnchor,
  },
}
</script>

<style lang="scss">
.project-residential-page {
  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }
  &__header {
    display: flex;

    @include respond-below('tablet') {
      flex-direction: column;
    }

    .residential-cover__wrapper,
    .summary {
      flex-grow: 1;
      flex-basis: 50%;
      flex-grow: 1;

      @include respond-below('phone') {
        flex-basis: initial;
      }
    }
  }

  .section {
    &__info {
      .container {
        display: grid;
        grid-gap: 6rem;
        grid-template-columns: repeat(2, 1fr);

        @include respond-below('phone') {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  .cta {
    background-color: $success;
  }
}
</style>
