<template>
  <span class="read-more">
    {{ expanded ? value : short }}
    <button
      v-if="!expanded && isAboveLimit"
      type="button"
      class="read-more__expand"
      @click="expanded = !expanded"
    >
      {{ $t('readMore') }}
    </button>
  </span>
</template>
<script>
export default {
  props: {
    value: String,
    limit: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      expanded: false,
    }
  },
  computed: {
    isAboveLimit() {
      return this.value.length > this.limit
    },
    short() {
      if (this.value.length <= this.limit) return this.value
      return this.value.substring(0, this.limit) + '...'
    },
  },
}
</script>
<style lang="scss">
.read-more {
  &__expand {
    margin-left: 5px;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
