<template>
  <div class="project-hero">
    <Slider
      v-if="items.length"
      :value="items"
      class="project-hero__slide"
      :display-thumbnail="true"
      :duartion="10000"
      :manual-navigation="true"
    />
    <div class="project-hero__content">
      <div class="project-hero__stats">
        <template v-for="({ key, type, label }, index) in enabledStatus">
          <div class="project-hero__stats-item">
            <div class="project-hero__stats-value">{{ getStat(key) }}</div>
            <div class="project-hero__stats-label">
              {{ $t(label || `residentialStatus:${type}.${key}`) }}
            </div>
          </div>
        </template>
        <div
          v-if="!stats.total"
          class="project-hero__stats-item project-hero__stats-item--coming"
        >
          <div class="project-hero__stats-value">{{ upcomingLabel }}</div>
        </div>
      </div>
      <ButtonComponent
        v-if="stats.total"
        :label="$t('findYourUnit')"
        icon-placement="left"
        theme="secondary"
        :icon="['fas', 'th']"
        class="mt-5"
        @click="ScrollToAnchor('residentials')"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Slider from '@kvass/media-render/Slider'
import { ScrollToAnchor } from '@/utils'

export default {
  computed: {
    ...mapState('Project', ['item']),
    items() {
      return this.$path('item.media.cover') || []
    },
    stats(){
      return this.$path('item.stats') || []
    },
    upcomingLabel() {
      return KvassConfig.get('customLabels.upcoming') || this.$t('upcomingLabel')
    },
    enabledStatus() {
      return [
        { key: 'sale', type: 'sale' },
        { key: 'sold', type: 'sale' },
        { key: 'rent', type: 'rent' },
        { key: 'rented', type: 'rent' },
        { key: 'reserved', type: 'sale' },
        { key: 'saleUpcoming', label: 'residentialStatus:sale.upcoming', type: 'sale' },
        { key: 'rentUpcoming', label: 'residentialStatus:rent.upcoming', type: 'rent' },
      ].filter(e => this.getStat(e.key))
    },
  },
  methods: {
    ScrollToAnchor,
    getStat(key) {
      if(this.stats[key]) return this.stats[key]

      let keys = key instanceof Array ? key : [key]
      return keys.reduce((r, c) => r + KvassConfig.get(`stats.${c}`) || 0, 0)
    },
  },
  components: {
    Slider,
  },
}
</script>

<style lang="scss">
.project-hero {
  $minHeight: 90vh;

  position: relative;
  min-height: $minHeight;
  background-color: css-alpha('primary', 0.07);

  img,
  &__slide {
    position: absolute;
    width: 100%;
    height: 100%;
    iframe {
      min-height: $minHeight;
    }
  }

  @include respond-below('phone') {
    min-height: 90vh;
  }

  &__content {
    position: absolute;
    bottom: 8rem;

    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 2rem;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  &__stats {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @include gap(1rem);

    @include respond-below('phone') {
      @include gap(0.5rem);
    }

    &-value {
      font-size: 2em;
      font-weight: bold;
      margin-bottom: 0.5rem;

      @include respond-below('phone') {
        font-size: 1.25em;
      }
    }

    &-label {
      color: $dark;
      font-size: 0.8em;

      @include respond-below('phone') {
        font-size: 0.7em;
      }
    }

    &-item {
      background-color: rgba(white, 0.9);
      border-radius: $border-radius;
      box-shadow: 0 10px 15px -10px rgba(black, 0.05);
      line-height: 1;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 1.5rem 0.5rem;
      width: 150px;

      @include respond-below('tablet') {
        width: 110px;
        height: auto;
        padding: 1rem 0.5rem;
      }

      &--coming {
        width: initial;
        padding: 1.5rem;
        font-size: 0.7em;

        & > * {
          margin: 0;
        }
      }

      $status: (
        'sold': $error,
        'sale': $success,
        'reserved': $warning,
      );

      @each $name, $hex in $status {
        &--#{$name} {
          color: $hex;
        }
      }
    }
  }
}
</style>
