<template>
  <div v-if="value" class="map">
    <LazyLoad>
      <MapComponent :center="$path('location.coordinates', value)" />
    </LazyLoad>
    <div class="map__address" v-if="value.formatted">
      {{ value.formatted }}
    </div>
    <div id="map" class="scroll-anchor"></div>
  </div>
</template>

<script>
import MapComponent from './Map'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    MapComponent,
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.map {
  position: relative;
  border: none;

  &__address {
    padding-top: 0.5rem;
    opacity: 0.3;

    @include respond-below('phone') {
      font-size: 1em;
    }
  }
}
</style>
