<template>
  <div class="residential-info">
    <h1 class="residential-info__title">{{ item.displayName || item.name }}</h1>
    <div class="residential-info__description" v-html="description"></div>
    <div class="row wrap g-1">
      <BrochureComponent :value="brochure" class="residential-info__brochure" />
      <ButtonComponent
        v-if="bidUrl && item.status === 'sale'"
        theme="secondary"
        :label="$t('bidAction')"
        :icon="['far', 'arrow-down']"
        tag="a"
        :href="`//${bidUrl}`"
        :track="{ event: 'navigate:buy', url: bidUrl }"
        target="_blank"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import BrochureComponent from '@/components/Brochure'

export default {
  computed: {
    ...mapState('Residential', ['item']),
    brochure() {
      return this.$path('item.project.media.brochure') || []
    },
    bidUrl() {
      return this.$path('item.sale.bidUrl')
    },
    description() {
      return this.$path('item.description')
    },
  },
  components: {
    BrochureComponent,
  },
}
</script>

<style lang="scss">
.residential-info {
  &__title {
    margin-bottom: 1.5rem;
    font-size: 2em;
  }

  &__description {
    line-height: 1.6;
    margin-bottom: 1.5rem;
  }
}
</style>
