<template>
  <Loader :value="promises.fetch" theme="default">
    <div class="project-page">
      <Hero class="section__hero" />
      <section class="section section__info">
        <ScrollAnchor value="map" offset="-8rem" :delay="750" />
        <div class="container">
          <Info class="grow-1" />
          <Map class="grow-1" />
        </div>
      </section>
      <section>
        <div v-if="showAdditionalSections" class="container section__additional-info">
          <ScrollAnchor value="additional-info" offset="-8rem" :delay="750" />
          <AdditionalInfo />
        </div>
      </section>
      <section v-if="showFlatfinder" class="section section--primary section__flatfinder">
        <ScrollAnchor value="flatfinder" offset="-8rem" :delay="750" />
        <Flatfinder />
      </section>
      <section v-if="showResidentials" class="section section__residentials">
        <div class="container">
          <ScrollAnchor value="residentials" offset="-8rem" :delay="750" />
          <Residentials />
        </div>
      </section>
      <section v-if="showResidentialTemplates" class="section section__residential-templates">
        <div class="container">
          <ScrollAnchor value="residential-templates" offset="-8rem" :delay="750" />
          <ResidentialTemplates />
        </div>
      </section>
      <section class="section section--primary section__lead">
        <div class="container">
          <div class="row wrap g-6">
            <div class="grow-1">
              <Openhouse v-if="showOpenhouse" class="mb-6" />
              <Roles />
            </div>
            <ScrollAnchor value="lead" offset="-8rem" :delay="750" />
            <Lead class="grow-1" />
          </div>
        </div>
      </section>
      <section v-if="$path('media.gallery.length', item)" class="section section__images">
        <div class="container">
          <ScrollAnchor value="gallery" offset="-8rem" :delay="750" />
          <Gallery />
        </div>
      </section>
      <section class="section section__attachments" v-if="$path('media.attachments.length', item)">
        <div class="container">
          <ScrollAnchor value="attachments" offset="-8rem" :delay="750" />
          <Attachments />
        </div>
      </section>

      <a href="#lead" class="cta" @click.prevent="ScrollToAnchor('lead')">
        <div class="mr-2">{{ $t('leadFormTitle') }}</div>
        <FontAwesomeIcon :icon="['far', 'arrow-down']" />
      </a>
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ProjectComponents from '@/components/Project'
import Residentials from '@/components/Residentials'
import BrowserApiMixin from '@/mixins/browser-api'
import { GetCustomField, ScrollToAnchor } from '@/utils'
import ScrollAnchor from '@kvass/scroll-anchor'

export default {
  mixins: [BrowserApiMixin('project')],
  computed: {
    ...mapState('Project', ['promises', 'item']),
    showFlatfinder() {
      return this.$path('item.flatfinders.length') && KvassConfig.get('stats.total')
    },
    showOpenhouse() {
      if (!this.item.id) return false
      if (this.$path('item.openhouses.length')) return true
      return !KvassConfig.get('siteSettings.hideNoOpenhouse')
    },
    showResidentialTemplates() {
      if (!this.item) return false
      return GetCustomField('residential-templates', this.item.customFields)
    },
    showResidentials() {
      return KvassConfig.get('stats.total') > 0
    },
    showAdditionalSections() {
      if (!this.item) return false
      let additionalInfo = this.$path('additional-info', this.item.customFields) || []

      return additionalInfo.some(i => Boolean(i.image || i.content))
    },
  },
  methods: {
    ...mapActions('Project', ['fetch']),
    GetCustomField,
    ScrollToAnchor,
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },
  components: {
    ...ProjectComponents,
    Residentials,
    ScrollAnchor,
  },
}
</script>

<style lang="scss">
.project-page {
  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }
  .section {
    &__info {
      .container {
        display: grid;
        grid-gap: 6rem;
        grid-template-columns: repeat(2, 1fr);

        @include respond-below('phone') {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
</style>
