<template>
  <div id="app" :class="['route--' + view.toLowerCase()]">
    <div class="demo-indicator" v-if="isDemo">
      <FontAwesomeIcon icon="exclamation-circle" />
      <span>{{ $t('thisIsADemo') }}</span>
    </div>
    <div v-if="$route.meta.navigation !== false" class="nav">
      <div class="container">
        <NavigationComponent
          :logo="logo"
          :items="items"
          :title="projectName"
          padding="15px 0"
          :action="mainAction || redirect(['Project', 'Residential'], { scrollTo: 0 })"
        />
      </div>
    </div>
    <router-view />
    <FooterComponent v-if="$route.meta.footer !== false" :navigation="items" :cookie-manager="CM" />
    <AssetComponent />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import API from './api'
import BrowserApiMixin from '@/mixins/browser-api'
import { Limit } from '@/filters'
import { GetCustomField } from '@/utils'

import { NavigationComponent } from 'vue-elder-navigation'
import AssetComponent, { EventBus as AssetBus } from '@kvass/asset-manager'
import FooterComponent from '@/components/Footer'
import loadCM from '@/gdpr'

export default {
  mixins: [BrowserApiMixin('app')],
  data() {
    return {
      customItems: [],
      CM: {},
    }
  },
  computed: {
    ...mapState('Root', ['item']),
    isSingular() {
      return API.projectType === 'singular'
    },
    isDemo() {
      return this.item.demo || false
    },
    view() {
      if (!this.isSingular) return this.$path('name', this.$route) || ''
      return 'ProjectResidential'
    },
    logo() {
      return API.logo
    },
    labels() {
      return KvassConfig.get('customLabels') || {}
    },
    projectName() {
      return this.item.name || ''
    },
    seo() {
      return this.$path('customFields.seo', this.item) || {}
    },
    hasFlatfinder() {
      return this.$path('flatfinders.length', this.item)
    },
    hasStatsTotal() {
      return this.$path('stats.total', this.item)
    },
    hasResidentials() {
      return this.$path('$store.state.Residentials.item.docs.length')
    },
    hasAttachments() {
      if (!this.isSingular) return this.$path('media.attachments.length', this.item)
      return this.$path('$store.state.Residential.item.media.attachments.length')
    },
    brochure() {
      return this.$path('media.brochure', this.item) || []
    },
    brochureStrategy() {
      return KvassConfig.get('siteSettings.brochureDownloadStrategy') || 'direct'
    },
    subpages() {
      if (!this.item) return
      return GetCustomField('subpages', this.item.customFields) || []
    },
    posts() {
      return this.$path('posts', this.item) || []
    },
    gallery() {
      if (!this.isSingular) return this.$path('media.gallery', this.item) || []
      return this.$path('$store.state.Residential.item.media.gallery') || []
    },
    items() {
      return [...this.defaultItems, ...this.customItems].sort((a, b) => a.order - b.order)
    },
    addons() {
      return this.$path('item.metadata.addons') || []
    },
    redirectUrl() {
      return (
        this.$path('item.publishing.redirectUrl') &&
        `https://${this.$path('item.publishing.redirectUrl')}`
      )
    },
    mainAction() {
      if (this.addons.includes('project:flatfinder')) return this.redirectUrl
      if (this.isSingular) return
      return this.redirect('Project', { scrollTo: 0 })
    },
    defaultItems() {
      return [
        {
          id: 'home',
          label: this.$t('theProject'),
          action: this.mainAction,
          condition: this.mainAction,
        },
        {
          id: 'flatfinder',
          label: this.$path('item.customFields.title-overrides.flatfinder') || KvassConfig.get('customLabels.flatfinder') || this.$tc('flatfinder'),
          exact: true,
          action: this.redirect(['Project', 'Residential'], { hash: '#flatfinder' }),
          condition: this.hasFlatfinder && this.hasStatsTotal,
        },
        {
          id: 'residentials',
          label: KvassConfig.get('customLabels.residentialList') || this.$tc('residential', 2),
          exact: true,
          action: this.redirect(['Project', 'Residential'], { hash: '#residentials' }),
          condition: !this.hasFlatfinder && this.hasResidentials,
        },
        ...this.posts.map(i => {
          if (!this.$path('customFields.add-to-navigation', i)) return {}
          return {
            id: i.title,
            label: i.title,
            exact: true,
            action: this.redirect(['posts'], { params: { slug: i.slug }, forceRedirect: true }),
          }
        }),
        {
          id: 'gallery',
          label: this.$tc('image', 2),
          action: this.redirect(['Project', 'Residential'], {
            hash: '#gallery',
          }),
          condition: this.gallery.length,
        },
        {
          id: 'map',
          label: this.$t('map'),
          action: this.redirect(['Project', 'Residential'], {
            hash: '#map',
          }),
        },
        {
          id: 'brochure',
          label: this.$t('brochure'),
          exact: true,
          target: '_blank',
          action: () => {
            if (this.brochure.length > 1) return
            AssetBus.emit('download', {
              type: 'brochure',
              strategy: this.brochureStrategy,
              asset: this.brochure[0],
            })
          },
          items:
            this.brochure.length > 1
              ? this.brochure.map(b => ({
                  label: Limit(b.name, 30),
                  action: () => {
                    AssetBus.emit('download', {
                      type: 'brochure',
                      strategy: this.brochureStrategy,
                      asset: b,
                    })
                  },
                  icon: ['fad', 'file-pdf'],
                }))
              : undefined,
          condition: this.brochure.length,
        },
        {
          id: 'attachments',
          label: this.$tc('attachment', 2),
          action: this.redirect(['Project', 'Residential'], {
            hash: '#attachments',
          }),
          condition: this.hasAttachments,
        },
        ...this.subpages.map(i => {
          if (!this.$path('add-to-navigation', i)) return {}
          return {
            id: i.title,
            label: i.title,
            exact: true,
            action: this.redirect(['Subpage'], { params: { slug: i.slug }, forceRedirect: true }),
          }
        }),
        {
          id: 'lead',
          label: this.$t('leadFormTitle'),
          action: this.redirect(['Project', 'Residential'], {
            hash: '#lead',
          }),
        },
      ]
        .filter(x => !('condition' in x) || x.condition)
        .filter(x => x.action || (x.items && x.items.length))
        .map((i, index) => ({ ...i, order: (index + 1) * 10 }))
    },
  },
  methods: {
    ...mapActions('Root', ['fetch']),
    redirect(name, options = { forceRedirect: false }) {
      return async function() {
        name = name instanceof Array ? name : [name]

        let { action, scrollTo, hash, params, forceRedirect } = options

        if (!name.includes(this.$route.name) || forceRedirect)
          await this.$router.push({ name: name[0], hash, params })
        else if (hash) {
          let target = document.querySelector(
            '.scroll-anchor--type-anchor.scroll-anchor--value-' + hash.substring(1),
          )
          if (!target) return
          target.scrollIntoView({ behavior: 'smooth' })
        }
        if (action) return this.action()
        if (scrollTo !== undefined) window.scrollTo(0, scrollTo)
      }
    },
  },
  created() {
    this.fetch().then(() => (this.CM = loadCM(this)))
  },
  metaInfo() {
    return {
      titleTemplate: this.projectName
        ? `%s | ${this.$path('seo.title') || this.projectName}`
        : `${this.$t('loading', { resource: null })}...`,
      link: [{ rel: 'icon', href: API.favicon, type: 'image/png' }],
    }
  },
  components: {
    NavigationComponent,
    FooterComponent,
    AssetComponent,
  },
}
</script>

<style lang="scss">
.nav {
  position: sticky;
  top: 0;
  z-index: get-layer('nav');
  background-color: white;
  box-shadow: 0 10px 15px -10px rgba(black, 0.05);
}

.demo-indicator {
  background-color: var(--warning);
  color: white;

  padding: 0.5em 0.75em;

  font-size: 0.8em;
  letter-spacing: 0.5px;
  text-align: center;

  svg {
    margin-right: 0.5em;
  }
}
</style>
