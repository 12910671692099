<template>
  <div class="residential-cover__wrapper content">
    <Slider
      class="residential-cover"
      v-if="items.length"
      :value="items"
      :display-thumbnail="true"
      :duartion="5000"
      :manual-navigation="true"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Slider from '@kvass/media-render/Slider'

export default {
  computed: {
    ...mapState('Residential', ['item']),
    items() {
      return this.$path('item.media.cover') || []
    },
  },
  components: {
    Slider,
  },
}
</script>

<style lang="scss">
.residential-cover {
  @include aspect-ratio(16, 9);

  &__wrapper {
    background-color: css-alpha('primary', 0.07);
    height: 50vh;

    @include respond-below('phone') {
      height: initial;
    }
  }
}
</style>
