<template>
  <div class="properties">
    <h2 class="properties__title">{{ $tc('residentialProperties', 2) }}</h2>
    <div class="properties__items">
      <div
        class="properties__item"
        v-for="(property, index) in items"
        :key="property.key + index"
        :data-key="property.key"
      >
        <div class="properties__item-label">
          {{ property.label || $tc(property.key) }}
        </div>
        <div class="properties__item-value">{{ property.formatted }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('Residential', ['item']),
    items() {
      return (this.$path('item.properties') || []).filter(p => p.formatted)
    },
  },
}
</script>

<style lang="scss">
.properties {
  &__title {
    margin-bottom: 1.5rem;
  }

  &__items {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  &__item {
    padding-bottom: 1em;
    border-bottom: 1px solid rgba(black, 0.1);

    &-label {
      opacity: 0.75;
    }

    &-value {
      font-weight: bold;
      font-size: 1.2em;
      margin-top: 0.25em;
    }
  }
}
</style>
