<template>
  <Gallery :value="items" class="project-gallery" />
</template>

<script>
import { mapState } from 'vuex'
import Gallery from '@kvass/template-gallery/Types/Gallery'

export default {
  computed: {
    ...mapState('Project', ['item']),
    items() {
      return this.$path('item.media.gallery') || []
    },
  },
  components: {
    Gallery,
  },
}
</script>

<style lang="scss">
.project-gallery {
}
</style>
